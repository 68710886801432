import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/tool',
		name: 'Home',
		meta: {
			showHeader: true,
			showFooter: true,
		},
		component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
	},
	{
		path: '*',
		redirect: '/tool',
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
