<template>
	<div id="app" class="appClass" :key="updateKey">
		<div id="pageScala" v-html="pageScala"></div>
		<div id="header" v-show="$route.meta.showHeader">
			<Header @showQrCodeDialog="handleShowQrCode" />
		</div>
		<router-view/>
		<div id="footer" v-show="$route.meta.showFooter">
			<Footer @showQrCodeDialog="handleShowQrCode" />
		</div>
	</div>
</template>

<script>
import Cookies from 'js-cookie';
import DevicePixelRatio from '@/lib/devicePixelRatio';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
	components: {
		Header,
		Footer,
	},
	data() {
		return {
			appClass: '',
			showQrCode: false,
			updateKey: false,
			pageScala: '',
		};
	},
	created() {
		this.webModeFunc();
		this.handleResize();
	},
	updated() {
		const cookieLang = Cookies.get('language') || 'zh';

		if (this.lang !== cookieLang) {
			this.$store.commit('setLang', cookieLang);
		}

		if (this.$route.name) {
			document.title = this.$t(`titleData.${this.$route.name}`);
		}
	},
	beforeMount() {
		window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
	methods: {
		webModeFunc() {
			let webMode = 1;
			if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
				webMode = 2; // 移动端
			} else {
				webMode = 1; // PC端

				const { userAgent } = navigator;
				if (userAgent.indexOf('Firefox') === -1) {
					new DevicePixelRatio().init();
				}
			}

			this.$store.commit('setWebMode', webMode);
			document.querySelector('body').className = `app-${webMode === 1 ? 'pc' : 'mobile'}`;
		},
		handleResize() {
			const { innerWidth } = window;
			const { webMode } = this.$store.state;

			// 以992px为界限，以上为pc模式，以下为H5模式
			if ((innerWidth >= 992 && webMode === 2) || (innerWidth < 992 && webMode === 1)) {
				this.$store.commit('setWebMode', innerWidth >= 992 ? 1 : 2);

				document.querySelector('body').className = `app-${innerWidth >= 992 ? 'pc' : 'mobile'}`;
			}

			const defalutPageWidth = 1920; // 设计默认尺寸

			let scale = 1;

			if (innerWidth > defalutPageWidth) {
				scale = innerWidth / defalutPageWidth;
			}
			const styleStr = `<style>.app-page-scala{transform: scale(${scale}) !important;}</style>`;
			this.pageScala = styleStr;

			this.$store.commit('setScala', scale);
		},
		handleShowQrCode() {
			this.showQrCode = true;
		},
	},
	computed: {
		loadingShow() {
			return this.$store.state.loading;
		},
		lang() {
			return this.$store.state.lang;
		},
	},
	watch: {
		lang: {
			immediate: true,
			handler(val, oldVal) {
				console.log(val, oldVal);
				this.updateKey = !this.updateKey; // 切换语言后强制重新渲染页面
				const lang = typeof val === 'string' ? val : Cookies.get('language') || 'zh';
				document.querySelector('html').lang = lang;
				document.querySelector('html').className = `app-${lang}`;

				if (this.$route.name) {
					document.title = this.$t(`titleData.${this.$route.name}`);
				}
			},
		},
	},
};
</script>

<style lang="scss">
body {
    margin: 0;
	// padding-top: 89px;
	// word-wrap: break-word;
	// word-break: break-all;
	.page-content-box {
		width: calc(100% * .6375);
		min-width: 1224px;
		margin: 0 auto;
	}
	img{
		image-rendering: -moz-crisp-edges;
		image-rendering: -o-crisp-edges;
		image-rendering: -webkit-optimize-contrast;
		image-rendering: crisp-edges;
		-ms-interpolation-mode: nearest-neighbor;
	}

	.font-red {
		color: #c3002f;
	}

	.full-width {
		width: 100%;
	}

	.hover-dom {
		cursor: pointer;
	}
	.hover-dom:hover {
		opacity: .9;
	}

	.line {
		display: block;
		font-size: 30px;
		font-weight: 600;
		line-height: 10px;
		margin-bottom: 10px;
	}
	.line-bold {
		font-size: 50px;
		line-height: 30px;
	}
	.line--red {
		color: #c3002f;
	}
	.line--white {
		color: #fff;
	}

	.el-checkbox__inner {
		margin-right: 10px;
		width: 24px !important;
		height: 24px !important;
		border-color: #D2D2D2 !important;
	}
	.el-checkbox__input.is-checked .el-checkbox__inner {
		background-color: #fff !important;
		border-color: #D2D2D2 !important;
	}
	.el-checkbox__inner::after {
		width: 8px;
		height: 15px;
		left: 6px;
		border: 1px solid #c3002f;
		border-left: 0;
		border-top: 0;
	}
	.el-checkbox__input.is-checked .el-checkbox__inner::after {

	}

	.el-input-number .el-input__inner {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.el-input-number__decrease {
		display: none !important;
	}
	.el-input-number__increase {
		display: none !important;
	}

	.el-select-dropdown {
		left: 0px !important;
	}

	.el-select-dropdown__item.selected {
		color: #333333 !important;
		font-weight: 400;
	}
	.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
		color: #C3002F !important;
	}
}
.html-data-box {
	p {
		margin: 0;
		word-wrap: break-word;
	}
}
.qrCode-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px !important;
    height: 450px !important;
    text-align: center;
    img {
      margin-top: -40px;
    }
    .qrCode-dialog-tips {
      font-size: 24px;
      color: #ABABAB;
    }
}
.app-pc {
	padding-top: 98px;
	.el-input__inner {
		border-radius: 0;
		height: 68px !important;
		line-height: 68px !important;
		font-size: 26px !important;
		color: #888888 !important;
		border-color: #D1D1D1 !important;
	}
	.el-select .el-input .el-select__caret {
		color: #C3002F !important;
		font-weight: bold;
	}
	.el-select-dropdown__item {
		height: 40px !important;
		line-height: 40px !important;
	}
	.el-select-dropdown__item.selected {}

	.page-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 40px;
		box-sizing: border-box;
		width: fit-content;
		min-width: 192px;
		max-width: 100%;
		height: 68px;

		font-size: 26px;
		color: #FFFFFF;
		line-height: 26px;
		cursor: pointer;
		user-select: none;
		text-decoration: none;
	}

	.page-btn:hover {
		opacity: .9;
	}

	.btn-red {
		background: #C3002F;
	}
	.btn-white {
		background: #fff;
		color: #888888;
		border: 1px solid #D1D1D1;
	}
	.btn-white-red {
		background: #fff;
		color: #C3002F;
		border: 1px solid #C3002F;
	}

	.page-btn-group {
		display: flex;
		justify-content: center;
		margin-top: 80px;
		margin-bottom: 114px;

		.page-btn {
			margin: 0 !important;
			margin-right: 66px !important;
		}
		.page-btn:last-child {
			margin-right: 0 !important;
		}
	}
}
.app-mobile {
	padding-top: 51px;
	overflow-x: hidden;
	.page-content-box {
		width: calc(100% * .855);
		min-width: calc(100% * .855);
		margin: 0 auto;
	}

	.el-dialog {
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		margin: 0 !important;
	}

	.qrCode-dialog {
		width: 80% !important;
		height: auto !important;
	}

	.el-input__inner {
		border-radius: 0;
		color: #888888 !important;
		border-color: #D1D1D1 !important;
	}
	.el-select .el-input .el-select__caret {
		color: #C3002F !important;
		font-weight: bold;
	}
	.el-select-dropdown__item.selected {}
	.mobile-select-box {
		.el-input.is-disabled .el-input__inner {
			color: #888888 !important;
			border-color: #D1D1D1 !important;
			background-color: #FFF;
		}
	}

	.line {
		display: block;
		font-size: 25px;
		font-weight: 600;
		line-height: 10px;
		margin-bottom: 10px;
	}

	.page-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 16px;
		box-sizing: border-box;
		width: fit-content;
		min-width: 113px;
		max-width: 100%;
		height: 40px;

		font-size: 15px;
		color: #FFFFFF;
		line-height: 15px;
		cursor: pointer;
		text-decoration: none;
	}

	.page-btn:hover {
		opacity: .9;
	}

	.btn-red {
		background: #C3002F;
	}
	.btn-white {
		background: #fff;
		color: #888888;
		border: 1px solid #D1D1D1;
	}
	.btn-white-red {
		background: #fff;
		color: #C3002F;
		border: 1px solid #C3002F;
	}

	.page-btn-group {
		display: flex;
		justify-content: center;
		margin-top: 50px;
		margin-bottom: 70px;

		.page-btn {
			margin: 0 !important;
			margin-right: 40px !important;
		}
		.page-btn:last-child {
			margin-right: 0 !important;
		}
	}
}
.app-zh {
	font-family: Noto Sans SC;
	input, textarea {
		font-family: Noto Sans SC;
	}
}
.app-en {
	// font-family: calibri;
	font-family: Source Sans Pro;
	input, textarea {
		// font-family: calibri;
		font-family: Source Sans Pro;
	}
}
.app-ja {
	font-family: Noto Sans JP;
	input, textarea {
		font-family: Noto Sans JP;
	}
	.app-pc {
		.qrCode-dialog {
			.qrCode-dialog-tips {
				padding: 0 30px !important;
				font-size: 24px !important;
				line-height: 28px !important;
			}
		}
	}
	.app-mobile {
		.qrCode-dialog {
			.qrCode-dialog-tips {
				padding: 0 10px !important;
				font-size: 24px !important;
				line-height: 28px !important;
			}
		}
	}
}
</style>
