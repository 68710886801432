<template>
  <div>
    <div class="page-header hidden-sm-and-down" v-if="webMode === 1">
      <div class="header-red-line">
        <!-- <div class="page-content-box header-sub">
          <el-dropdown
            :class="`lang-select ${showLangMenu ? 'show-lang-menu' : ''}`"
            @visible-change="handleLangMenu"
            @command="handleLangChange"
          >
            <span class="el-dropdown-link">
              <font :class="getLangNodeClass('zh')">语言</font>
              <font class="lang-node-border">|</font>
              <font :class="getLangNodeClass('en')">LANGUAGE</font>
              <font class="lang-node-border">|</font>
              <font :class="getLangNodeClass('ja')">言語</font>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="lang-select-poper">
              <el-dropdown-item
                v-for="item in langData"
                :key="item.value"
                :command="item.value"
              >
                {{ item.label }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div> -->
      </div>
      <div class="header-menu-dom">
        <div class="page-content-box header-menu">
          <img class="header-logo" src="/imgStatic/logo.png" alt="" @click="handleToPage('/')" />
        </div>
      </div>
      <div id="test" v-html="langSwitchStyle"></div>
    </div>
    <div class="page-header page-header-mobile hidden-md-and-up" v-if="webMode === 2">
      <!-- <div class="header-red-line">
        <div class="page-content-box header-sub">
          <img class="wx-share" src="/imgStatic/wechat.png" @click="handleShowQrCode" />
          <el-dropdown
            class="lang-select"
          >
            <span class="el-dropdown-link" @click="handleMobileLangChange">
              <font :class="getLangNodeClass('zh')">语言</font>
              <font class="lang-node-border">|</font>
              <font :class="getLangNodeClass('en')">LANGUAGE</font>
              <font class="lang-node-border">|</font>
              <font :class="getLangNodeClass('ja')">言語</font>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="lang-select-poper">
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div> -->
      <div class="header-box-mobile">
        <div class="page-content-box header-box-mobile-dom">
          <!-- <img class="header-menu-icon" src="/imgStatic/menu.png" alt="" @click="handleShowMobileMenu()" />
          <div class="header-company">{{ $t("headerData.headerCompanyMobile") }}</div> -->
          <img class="header-logo" src="/imgStatic/logo.png" alt="" @click="handleToPage('/')" />
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="showLangDialog"
      :lock-scroll="false"
      :show-close="false"
      custom-class="lang-switch-dialog"
    >
      <div
        v-for="item in langData"
        :key="item.value"
        class="lang-switch-node"
        v-text="item.label"
        @click="handleLangChange(item.value)"
      ></div>
    </el-dialog>
    <el-dialog
      :visible.sync="showMobileMenu"
      :lock-scroll="false"
      :fullscreen="true"
      custom-class="mobile-menu-dialog"
    >
      <div class="mobile-menu-list">
        <div
          v-for="item in menuData"
          :key="item.id"
          class="mobile-menu-node"
          v-text="getLangContent(item.name)"
          @click="handleToPage(item.url, item)"
        ></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

import {
} from '@/api/common';

import {
	getUrlKey,
	deleteUrlKey,
} from '@/lib/util';

export default {
	name: 'Header',
	props: {},
	components: {
	},
	data() {
		return {
			selectLang: 1,
			langData: [
				{
					label: this.$t('config.langZh'),
					value: 'zh',
				},
				{
					label: this.$t('config.langEn'),
					value: 'en',
				},
				// todo 暂时隐藏日文选项
				// {
				// 	label: this.$t('config.langJa'),
				// 	value: 'ja',
				// },
			],
			menuData: [],
			showLangMenu: false,
			showLangDialog: false,
			showMobileMenu: false,
			iframeShowData: {},
			defaultIframeType: 0,
			langSwitchStyle: '',
		};
	},
	created() {
		const lang = getUrlKey('lang') || '';

		let selectLang = Cookies.get('language') || 'zh';

		if (lang) {
			selectLang = lang;

			this.handleLangChange(lang);

			setTimeout(() => {
				window.history.replaceState('', '', deleteUrlKey('lang'));
			}, 1000);
		}

		this.selectLang = selectLang;

		this.$nextTick(() => {
			this.init();
		});
	},
	methods: {
		init() {
		},
		handleLangChange(key) {
			const lang = key;

			// 切换语言cookie，本地化i18n配置，当前页title
			this.showLangDialog = false; // mobile模式关闭选择语言dialog
			this.selectLang = lang;
			Cookies.set('language', lang);
			this.$i18n.locale = lang;
			this.$store.commit('setLang', lang);
		},
		handleMobileLangChange() {
			this.showLangDialog = true;
		},
		handleLangMenu(visible) {
			this.showLangMenu = visible;

			const left = document.querySelector('.lang-select').offsetLeft;
			const styleStr = `<style>.lang-select-poper{left:${left}px !important;}</style>`;
			this.langSwitchStyle = styleStr;
		},
		handleToPage(path, item) {
			if (item && item.target === 'modal') {
				this.showMobileMenu = false;
				this.iframeShowData = item;
				this.defaultIframeType = 1;
			} else if (item && item.target === 'new') {
				window.open(path);
			} else {
				if (this.$route.path === path) {
					return;
				}

				window.location.href = path;
			}
		},
		handleShowQrCode() {
			this.$emit('showQrCodeDialog');
		},
		handleShowMobileMenu() {
			this.showMobileMenu = true;
		},
		handleCloseIframe() {
			this.defaultIframeType = 0;
		},
	},
	computed: {
		webMode() {
			return this.$store.state.webMode;
		},
		getMenuClass() {
			return item => {
				let className = 'header-menu-list-node';

				if (this.$route.path === item.url) {
					className = 'header-menu-list-node header-menu-list-node-select';
				}

				return className;
			};
		},
		getLangNodeClass() {
			return key => {
				let className = 'lang-node';

				if (this.selectLang === key) {
					className = 'lang-node lang-node-select';
				}

				return className;
			};
		},
	},
	watch: {
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .page-header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 97px;
    z-index: 1000;
    border-bottom: 1px solid #ededed;
    .header-red-line {
      width: 100%;
      height: 40px;
      background: #C3002F;
      overflow: hidden;
      .header-sub {
        height: 100%;
        display: flex;
        align-items: center;
        .wx-share {
          margin-left: auto;
          cursor: pointer;
        }
        .wx-share:hover {
          opacity: .6;
        }
        .lang-select {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 150px; // todo 三语言版本为190px
          height: 100%;
          margin-left: 10px;
          cursor: pointer;
          .el-dropdown-link {
            font-size: 12px;
            cursor: pointer;
            .lang-node {
              color: #DF9FA9;
            }
            .lang-node-select {
              color: #F9F9F9;
            }
            .lang-node-border {
              padding: 0 6px;
              color: #DF9FA9;
            }
            i {
              color: #F9F9F9;
              font-weight: bold;
            }
          }
        }
        .lang-select:hover {
          background: #a60028;
        }
        .show-lang-menu {
          background: #a60028;
        }
      }
    }
    .header-menu-dom {
      width: 100%;
      height: 56px;
      border-bottom: 1px solid #ededed;
      background: #fff;
      .header-menu {
        display: flex;
        align-items: center;
        height: 100%;
        .header-logo {
          height: 19px;
          cursor: pointer;
        }
        .header-company {
          font-size: 16px;
          color: #333333;
          margin-left: 55px;
        }
        .header-menu-list {
          display: flex;
          margin-left: auto;
          height: 100%;
          font-size: 14px;
          color: #333333;
          .header-menu-list-node {
            position: relative;
            display: flex;
            justify-content: center;
            min-width: 56px;
            height: 100%;
            display: flex;
            align-items: center;
            margin-left: 35px;
            font-size: 16px;
            cursor: pointer;
            user-select: none;
            white-space: nowrap;
          }
          .header-menu-list-node::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 5px;
            width: calc(100% - 10px);
            height: 2px;
            background: #c3002f;
            -webkit-transform-origin: right top;
            transform-origin: right top;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transition: transform .3s cubic-bezier(.25,.46,.45,.94);
            -webkit-transition: -webkit-transform .3s cubic-bezier(.25,.46,.45,.94);
            transition: -webkit-transform .3s cubic-bezier(.25,.46,.45,.94);
            transition: transform .3s cubic-bezier(.25,.46,.45,.94);
            transition: transform .3s cubic-bezier(.25,.46,.45,.94),-webkit-transform .3s cubic-bezier(.25,.46,.45,.94);
          }
          .header-menu-list-node:hover::after {
            -webkit-transform-origin: left top;
            transform-origin: left top;
            -webkit-transform: scale(1);
            transform: scale(1);
          }
          .header-menu-list-node-select::after {
            -webkit-transform-origin: left top;
            transform-origin: left top;
            -webkit-transform: scale(1);
            transform: scale(1);
          }
          .header-menu-list-node:hover {
            // opacity: .7;
          }
          .header-menu-list-node:nth-child(1) {
            margin-left: 0;
          }
          .header-menu-list-node-select {
            color: #C3002F;
            cursor: auto;
          }
          .header-menu-list-node-select:hover {
            opacity: 1;
          }
        }
      }
    }
  }
  .page-header-mobile {
    height: 50px;
     .header-red-line {
       height: 30px;
       /deep/.lang-select {
        justify-content: flex-end !important;
       }
     }
    .header-box-mobile {
      height: 100%;
      background: #fff;
      .header-box-mobile-dom {
        display: flex;
        align-items: center;
        height: 100%;
        background: #fff;
        .header-menu-icon {
          width: 22px;
          height: 17px;
        }
        .header-company {
          margin-left: 20px;
          font-size: 14px;
          color: #333333;
        }
        .header-logo {
          height: 15px;
          // margin-left: auto;
          // margin-right: 15px;
        }
      }
    }
  }
  /deep/.lang-switch-dialog {
    width: 75%;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      background-color: #C3002F;
      .lang-switch-node {
        display: flex;
        align-items: center;
        height: 78px;
        padding-left: 30px;
        font-size: 30px;
        color: #F9F9F9;
      }
    }
  }
  /deep/.mobile-menu-dialog {
    background: #c3002f;
    .el-dialog__close {
      color: #fff;
    }
    .el-icon-close:before {
      font-size: 25px;
      color: #fff;
    }
    .mobile-menu-list {
      padding: 0 25px;
      .mobile-menu-node {
        margin-bottom: 30px;
        font-size: 20px;
        color: #F9F9F9;
      }
    }
  }
  /deep/.new-iframe-dialog {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    z-index: 10000;
    background: #fff;
    .iframe-dialog-header {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 30px;
      background: #f7f7f7;
      border-bottom: 1px solid #ededed;
      cursor: move;
      .iframe-icon-dom {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #333333;
        img {
          width: 16px;
          margin-right: 15px;
        }
      }
      .iframe-action-dom {
        display: flex;
        align-items: center;
        margin-left: auto;
        i {
          margin-left: 20px;
          cursor: pointer;
        }
      }
    }
    .iframe-content {
      width: 100%;
      height: calc(100% - 40px);
      iframe {
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }
  /deep/.new-iframe-dialog-min {
    display: none;
  }
  /deep/.new-iframe-dialog-max {
    left: 0 !important;
    top: 0 !important;
    transform: translate(0, 0);
    width: 100%;
    height: 100%;
  }
  /deep/.iframe-tips-bar {
    position: fixed;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 300px;
    height: 40px;
    padding: 0 10px;
    z-index: 1000;
    background: #C3002F;
    .iframe-icon-dom {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #FFFFFF;
      img {
        width: 16px;
        margin-right: 15px;
      }
    }
    .iframe-action-dom {
      display: flex;
      align-items: center;
      margin-left: auto;
      i {
        margin-left: 20px;
        cursor: pointer;
        color: #FFFFFF;
      }
    }
  }
</style>
<style lang="scss">
  .lang-select-poper {
    min-width: 180px !important; // todo 三语言版本为225px
    top: 0 !important;
    margin-top: 32px !important;
    background-color: #a60028 !important;
    border: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    .popper__arrow {
      display: none;
    }
    .el-dropdown-menu__item {
      padding: 0 12px;
      color: #F9F9F9;
      font-size: 16px;
    }
    .el-dropdown-menu__item.hover, .el-dropdown-menu__item:hover {
      background-color: #C3002F;
      font-weight: bold;
    }
    .el-dropdown-menu__item.selected, .el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
      color: #F9F9F9;
      font-weight: bold;
      background-color: #C3002F;
    }
  }
  .lang-select-poper[x-placement^=bottom] .popper__arrow {
    display: none;
  }
  .app-pc {
    .lang-select {
      width: 180px !important; // todo 三语言版本为225px
      .el-dropdown-link {
        font-size: 16px !important;
      }
    }
  }
  .app-mobile {
    .iframe-content {
      overflow-x: scroll;
      overflow-y: hidden;
      iframe {
        width: 850px !important;
      }
    }
    .iframe-dialog-header {
      padding: 0 10px !important;
      .iframe-icon-dom {
        font-size: 10px !important;
        img {
          width: 15px;
          margin-right: 15px;
        }
      }
    }
    .iframe-tips-bar {
      width: 200px;
      height: 40px;
      .iframe-icon-dom {
        font-size: 10px;
        img {
          width: 10px;
          margin-right: 5px;
        }
      }
      .iframe-action-dom {
        display: flex;
        align-items: center;
        margin-left: auto;
        i {
          margin-left: 20px;
          cursor: pointer;
        }
      }
    }
    .lang-select-poper {
      display: none;
    }
  }
</style>
<style scoped lang="scss">
  .app-en {
    .app-pc {
      .header-company {
        width: 300px;
        font-size: 16px !important;
      }
      .header-menu-list-node {
        font-size: 16px;
      }
    }
  }
  .app-ja {
    .app-pc {
      .header-company {
        font-size: 14px !important;
      }
      .header-menu-list-node {
        margin-left: 21px !important;
      }
      .header-menu-list-node:nth-child(1) {
        margin-left: 0 !important;
      }
    }
    .app-mobile {
      .header-company {
        font-size: 13px !important;
      }
    }
  }
</style>
