/*eslint-disable*/
import cloneDeep from 'lodash/cloneDeep';
import Cookies from 'js-cookie';

export function clone(data) {
	return cloneDeep(data);
}

export function getUrlKey(name) { // 获取url 参数
	return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20')) || null;
}

export function getLangContent(obj) { // 获取语言对应的内容
	const keyConfig = {
		zh: 'cn',
		en: 'en',
		ja: 'jp',
	};
	const lang = Cookies.get('language') || 'en';

	return obj ? obj[keyConfig[lang]] : '';
}

export function deleteUrlKey(name) {
	var loca = window.location;
	var baseUrl = loca.origin + loca.pathname + "?";
	var query = decodeURIComponent(loca.hash.split('?')[1]);
	if(!query){
	  return loca
	}
	if (loca.href.indexOf(name) > -1) {
	  var obj = {}
	  var arr = query.indexOf('&')>-1?query.split("&"):[query];
	  for (var i = 0; i < arr.length; i++) {
		arr[i] = arr[i].split("=");
		obj[arr[i][0]] = arr[i][1];
	  };
	  delete obj[name];

	  const urlKey = JSON.stringify(obj).replace(/[\"\{\}]/g, "").replace(/\:/g, "=").replace(/\,/g, "&");

	  var url = window.location.pathname + (urlKey ? ('?' + urlKey) : '');
	  console.log(123, urlKey, url)
	  return url
	};
}

export default {
	clone,
	getUrlKey,
	getLangContent,
	deleteUrlKey,
};
