import axios from '@/lib/axios';

const host = '';

/**
 * 获取国家地区
 */
export function apiCountry(params) {
	return axios.get(`${host}/api/country/`, params);
}

/**
 * 获取flow相关数据
 */
export function apiFlow(params) {
	return axios.get(`${host}/api/flow`, params);
}

/**
 * 获取modelName列表
 */
export function apiGetModelNameList(params) {
	return axios.get(`${host}/api/refrigerant/model_names`, params);
}

/**
 * 获取modelName详情
 */
export function apiGetModelNameDetail(params) {
	return axios.get(`${host}/api/refrigerant/by-model-name/${params.modelName}`, {});
}

/**
 * download地址
 */
export function apiDownloadURL(params) {
	return `${host}/api/record/download/${params.key}`;
}

/**
 * 提交计算
 */
export function apiCalculate(params) {
	return axios.formPost(`${host}/api/record/calculate/`, params);
}
