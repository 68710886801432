import Vue from 'vue';
import ElementUI from 'element-ui';
import _axios from '@/lib/axios';
import {
	getLangContent,
} from '@/lib/util';
import i18n from './lang';
import App from './App.vue';
import router from './router';
import store from './store';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import '@/assets/css/font.css';
import '@/assets/css/notoSansSc.css';
import '@/assets/css/notoSansJp.css';

Vue.use(ElementUI);

// MOCK
// require('./mock');

Vue.config.productionTip = false;
Vue.prototype.$axios = _axios;
Vue.prototype.getLangContent = getLangContent;

router.beforeEach((to, from, next) => {
	next();
});
// eslint-disable-next-line no-unused-vars
router.afterEach((to, from, next) => {
	window.scrollTo(0, 0);
});

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app');
