/* eslint-disable*/
export default {
    config: {
        langZh: "中文",
        langEn: "English",
        langJa: "日本語",
    },
    titleData: {
        Home: "Tool",
    },
    axiosData: {
        noApiErrorTips: 'The requested address does not exist',
        serviceErrorTips: 'service error',
    },
    headerData: {},
    footerData: {
        footerTitle1: 'EQUIPMENT',
        footerList1_1: 'AC IoT Apps & Controllers',
        footerList1_2: 'Air to Water Heat Pumps & DHW',
        footerList1_3: 'All Commercial AC & VRF Systems',
        footerList1_4: 'All Home Air Conditioners',
        footerList1_5: 'Chillers',
        footerList1_6: 'Commercial IoT Solutions & Controllers',
        footerList1_7: 'Commercial Split Systems',
        footerList1_8: 'IAQ Solutions',
        footerList1_9: 'Multi-Zone AC',
        footerList1_10: 'Room AC',
        footerList1_11: 'VRF Systems',
        footerTitle2: 'USEFUL LINKS',
        footerList2_1: 'Where to buy',
        footerList2_2: 'Resources',
        footerList2_3: 'About Us',
        footerList2_4: 'Contact us',
        footerList2_5: 'airCloud apps',
        footerList2_6: 'Projects',
        footerList2_7: 'Sign in',
        footerTitle3: 'Industry Applications',
        footerList3_1: 'Restaurants',
        footerList3_2: 'Retail / Shops',
        footerList3_3: 'Lodging / Hotels',
        footerList3_4: 'Offices',
        footerList3_5: 'Healthcare',
        lawTitle: "Legal Policy",
        privacyTitle: "Privacy Center",
        copyright1: '© 2024 JOHNSON CONTROLS - HITACHI AIR CONDITIONING COMPANY',
    },
    pageDom: {
        previousBtn: 'Previous',
        nextBtn: 'Next',
        submitBtn: 'Submit',
        downloadBtn: 'Download',
        restartBtn: 'Start a new calculation',
    },
    toolData: {
        introTitleSplitData: {
            introTitle1: 'R32',
            introTitle2: 'Calculation',
            introTitle3: 'Tool',
        },
        introTitle: 'R32 Calculation Tool',
        stepList: [
            'System',
            'Refrigerant',
            'Indoor',
            'Summary',
            'Report',
        ],
        yesOrNoData: [
            'No',
            'Yes',
        ],
        stepBeforeData: {
            introTitle: 'Terms and Conditions',
            introContentData: {
                introContent1: '',
                introContent2: 'Please select the region/country where your heat pump system to be installed.',
            },
            introParagraph: [
                '<strong class="intro-title">Tools Overview</strong>',
                'In 2024, Johnson Controls-Hitachi introduced the airCore700, a large single split heat pump system for buildings equipped with the new refrigerant R32. The new refrigerant, R32, has a global warming potential of about 1/4 that of R410A and can reduce the amount of refrigerant by about 15%, which is expected to help maintain comfort while reducing environmental impact. However, R32 is slightly flammable, and depending on the refrigerant concentration, it may combust and cause serious damage. Therefore, a new rule has been established to determine whether safety measures are required for buildings and projects where heat pump systems with the new refrigerant R32 are installed, based on the area and height of each space and room, as well as the potential amount of refrigerant leakage. To provide you with the installation recommendations, you will be asked to enter the necessary information in the order shown in the tool: system, refrigerant, and indoor units. For each space entered, the tool will generate a summary of the results. The suggestions of “allowed/not allowed to install” or “leak sensor and circulation required when installing this heat pump system” can be listed and saved. The report output can facilitate the smooth consideration of implementing R32 refrigerant heat pump systems.',
                '<br /><strong class="intro-title">Privacy Policy</strong>',
                'The personal information entered will be temporarily stored for the duration of the use of this tool and will be discarded when the use of the tool is terminated. Once you exit the tool, the entered personal information will be discarded. When you use the tool again, you will need to enter your personal information again.',
                '"While using this tool" refers to the state in which you are accessing this website. If you close the browser screen that is currently displayed or do not perform any operation for a few hours, your use of the tool will be terminated.',
                '<br /><strong class="intro-title">Disclaimer (Rules & Version)</strong>',
                'This tool is intended to determine whether safety measures are required when installing Hitachi brand single split heat pump systems that use R32 refrigerant. It cannot be used for other categories of Hitachi brand products or other brands of air conditioners or heat pump systems.',
                `
                This tool was created based on UL 60335-2-40 Fourth Edition, the Standard for Household and Similar Electrical Appliances - Safety - Part 2-40: Particular Requirements for Electrical Heat Pumps, Air-Conditioners, and Dehumidifiers.
                <br />
                This tool is updated without notice whenever relevant standards are revised. Although maintenance is performed from time to time, the software may not always be the latest version due to software availability. Please be sure to check the relevant conventions and versions above.
                <br />
                Based on the input of the creator, this tool determines whether safety measures are required for each space and suggests the quantity of related components needed to implement them. However, there are various ways to implement safety measures, and the following methods may make it unnecessary to install the proposed tax countermeasure-related components.
                <ul>
                    <li>Provide space under door and door rally.</li>
                    <li>Raise the installation height of the indoor unit.</li>
                    <li>Smaller total refrigerant volume per refrigerant system</li>
                </ul>
                For more information, please see the document included when downloading the judgment results.
                `,
                `
                The information entered in this tool is saved except for personal information. Previously entered data can be recovered from the "Past" page by using the reference number and password displayed on the save screen. Data is stored for 5 years after entry.
                <br />
                If you use this tool to input data for a project in which other than "Hitachi brand large single split heat pump system [airCore700]" are installed, there is a possibility that the safety standards will differ from the usual standards. For details, please contact.
                `,
            ],
            introCheckData: {
                introCheck1: 'By clicking on the box, you agree to our',
                introCheck2: 'Terms and Conditions',
                introCheck3: 'including the limitations with using the R32 Calculator.',
            },
            checkErrorTips: 'please read and agree to our Terms and Conditions',
        },
        areaSelectData: [],
        systemSelectData: ['Single split', 'mutil split'],
        roomSelectData: ['single room', 'multiple rooms'],
        cardListData: ['Ducted', 'Cassette', 'High Wall', 'Ceiling', 'Floor Standing',],
        formTipsList: {
            title1: '1. Please select your heat pump product/system type',
            title2: '2. Please select single room or multiple rooms installation.',
            title3: '3. Enter the total number of the room(s) in your installation area.',
            title4: '4. Please select your indoor unit type.',
            title5: '5. Select your indoor unit installation height.',
            title6: '6. Enter the outdoor unit model name.',
            title7: '7. Enter the piping length.',
            title7Tips: 'Maximum piping length of {modelName} is {num1} [{num2}].',
            title8: '8. Enter the height difference between outdoor unit and indoor unit.',
            title8Tips: 'Maximum height difference between ODU and IDU of {modelName} is {num1} [{num2}].',
            title9: '9. Enter total installation floor area.',
            title10: '10. Is your heat pump system located at underground floor?',
        },
        confirmFormData: {
            form1: 'Type of product',
            form2: 'Installation zone type',
            form3: 'Number of Room(s)',
            form4: 'IDU type',
            form5: 'IDU installation height',
            form6: 'ODU model name',
            form7: 'Piping length',
            form8: 'Height difference',
            form9: 'Installation floor area',
        },
        step1Data: {
            title: 'Step1: System',
            roomNumErrorTips: 'please enter the total number of the room(s) in your installation area',
            installationHeightErrorTips: 'please enter the total number of the room(s) in your installation area',

        },
        step2Data: {
            title: 'Step2: Refrigerant',
            modelNameErrorTips: 'please select the outdoor unit model name',
            pipingLengthErrorTips: 'please enter the piping length',
            unitHeightErrorTips: 'please enter the height difference between outdoor unit and indoor unit',
        },
        step3Data: {
            title: 'Step3: Indoor',
            floorAreaTemplate: 'Installation Floor area Room{num}',
            installationTotalErrorTips: 'please enter total installation floor area',
        },
        step4Data: {
            title: 'Step3: Indoor',
            floorAreaTemplate: 'Room{num}',
        },
        step5Data: {
            title: 'R32 Calculation Report',
            tips: 'According to your input data,',
            resultTips1: 'The total refrigerant of your heat pump system is {num1} lbs [{num2} kg].',
            resultTips2: 'The allowed minimum installation area of EACH ROOM is {num1} sqft [{num2} sqm].',
            resultTips2_1: 'The allowed minimum installation area of EACH ROOM is {num1} sqft [{num2} sqm].',
            resultTips2_2: 'The allowed minimum installation area of TOTAL ROOMS is {num1} sqft [{num2} sqm].',
            resultTips2_3: 'The allowed minimum installation floor area of Total Rooms is {num1} sqft[{num2} sqm] when leak sensor and circulation are applied.',
            resultTips: 'Installation of the heat pump system is not allowed in the area.',
            notice1_1: 'Disclaimer: ',
            notice1_2: 'This application tool is designed to simulate the necessity of a leak sensor based on compliance with minimum required room size. However, it is important to note that the results obtained from this simulation cannot be used for any engineering calculations or decision-making purposes. The tool is intended for informational and illustrative purposes only.',
            notice1_3: 'The information provided by this simulation should not be considered as professional engineering advice or a substitute for proper engineering analysis. Users are advised to consult qualified engineers or professionals for accurate and detailed assessments related to leak detection systems and compliance with room size requirements.',
            notice1_4: 'The creators and developers of this application tool do not assume any liability for decisions made based on the results of this simulation. Users utilize this tool at their own risk and discretion.',
            notice2_1: 'Disclaimer: ',
            notice2_2: 'This application tool is designed to simulate the necessity of a leak sensor based on compliance with minimum required room size. However, it is important to note that the results obtained from this simulation cannot be used for any engineering calculations or decision-making purposes. The tool is intended for informational and illustrative purposes only.',
            notice2_3: 'The information provided by this simulation should not be considered as professional engineering advice or a substitute for proper engineering analysis. Users are advised to consult qualified engineers or professionals for accurate and detailed assessments related to leak detection systems and compliance with room size requirements.',
            notice2_4: 'The creators and developers of this application tool do not assume any liability for decisions made based on the results of this simulation. Users utilize this tool at their own risk and discretion.',
            result1: 'The installation of the heat pump system is not allowed in the area.',
            result2: 'No room area restrictions when installing the heat pump system.',
            result3: 'The installation of the heat pump system is allowed in the area.',
            result4: 'Leak sensor and circulation is required when installing this heat pump system.',
        },
    },
};
