<template>
	<div class="page-footer footer">
		<div class="page-content-box">
			<el-row>
				<el-col :span="24" class="hidden-md-and-up">
          <div class="logo-area logo-area-mobile">
            <a href="https://www.jci-hitachi.com/" target="_blank">
              <img src="/imgStatic/logo-w.svg" alt="">
            </a>
          </div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="10" class="menu-dom">
          <div class="footer-col-dom-box">
            <div class="footer-col-dom">
              <h5>{{ $t("footerData.footerTitle1") }}</h5>
              <ul>
                <li>
                  <a href="https://www.hitachiaircon.com/ranges/iot-apps-controllers" target="_blank">
                    {{ $t("footerData.footerList1_1") }}
                  </a>
                </li>
                <li>
                  <a href="https://www.hitachiaircon.com/ranges/heating" target="_blank">
                    {{ $t("footerData.footerList1_2") }}
                  </a>
                </li>
                <li>
                  <a href="https://www.hitachiaircon.com/ranges/commercial-ac" target="_blank">
                    {{ $t("footerData.footerList1_3") }}
                  </a>
                </li>
                <li>
                  <a href="https://www.hitachiaircon.com/ranges/air-conditioning" target="_blank">
                    {{ $t("footerData.footerList1_4") }}
                  </a>
                </li>
                <li>
                  <a href="https://www.hitachiaircon.com/ranges/chillers" target="_blank">
                    {{ $t("footerData.footerList1_5") }}
                  </a>
                </li>
                <li>
                  <a href="https://www.hitachiaircon.com/ranges/commercial-iot-apps-controllers" target="_blank">
                    {{ $t("footerData.footerList1_6") }}
                  </a>
                </li>
                <li>
                  <a href="https://www.hitachiaircon.com/ranges/single-zone-ac" target="_blank">
                    {{ $t("footerData.footerList1_7") }}
                  </a>
                </li>
                <li>
                  <a href="https://www.hitachiaircon.com/ranges/indoor-air-quality" target="_blank">
                    {{ $t("footerData.footerList1_8") }}
                  </a>
                </li>
                <li>
                  <a href="https://www.hitachiaircon.com/ranges/multi-room-ac" target="_blank">
                    {{ $t("footerData.footerList1_9") }}
                  </a>
                </li>
                <li>
                  <a href="https://www.hitachiaircon.com/ranges/ac-for-single-room" target="_blank">
                    {{ $t("footerData.footerList1_10") }}
                  </a>
                </li>
                <li>
                  <a href="https://www.hitachiaircon.com/ranges/vrf-systems" target="_blank">
                    {{ $t("footerData.footerList1_11") }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="footer-col-dom">
              <h5>{{ $t("footerData.footerTitle2") }}</h5>
              <ul>
                <li>
                  <a href="https://www.hitachiaircon.com/where-to-buy" target="_blank">
                    {{ $t("footerData.footerList2_1") }}
                  </a>
                </li>
                <li>
                  <a href="https://www.hitachiaircon.com/resources" target="_blank">
                    {{ $t("footerData.footerList2_2") }}
                  </a>
                </li>
                <li>
                  <a href="https://www.hitachiaircon.com/about" target="_blank">
                    {{ $t("footerData.footerList2_3") }}
                  </a>
                </li>
                <li>
                  <a href="https://www.hitachiaircon.com/contact-us" target="_blank">
                    {{ $t("footerData.footerList2_4") }}
                  </a>
                </li>
                <li>
                  <a href="https://www.hitachiaircon.com/apps" target="_blank">
                    {{ $t("footerData.footerList2_5") }}
                  </a>
                </li>
                <li>
                  <a href="https://www.hitachiaircon.com/projects" target="_blank">
                    {{ $t("footerData.footerList2_6") }}
                  </a>
                </li>
                <li>
                  <a href="https://www.hitachiaircon.com/sign-in" target="_blank">
                    {{ $t("footerData.footerList2_7") }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
				</el-col>
        <el-col :xs="24" :sm="24" :md="5" class="last-menu-dom">
          <div class="footer-col-dom">
            <h5>{{ $t("footerData.footerTitle3") }}</h5>
            <ul>
              <li>
                <a href="https://www.hitachiaircon.com/business-applications/restaurants" target="_blank">
                  {{ $t("footerData.footerList3_1") }}
                </a>
              </li>
              <li>
                <a href="https://www.hitachiaircon.com/business-applications/retail-shops" target="_blank">
                  {{ $t("footerData.footerList3_2") }}
                </a>
              </li>
              <li>
                <a href="https://www.hitachiaircon.com/business-applications/lodging-hotels" target="_blank">
                  {{ $t("footerData.footerList3_3") }}
                </a>
              </li>
              <li>
                <a href="https://www.hitachiaircon.com/business-applications/offices" target="_blank">
                  {{ $t("footerData.footerList3_4") }}
                </a>
              </li>
              <li>
                <a href="https://www.hitachiaircon.com/business-applications/healthcare" target="_blank">
                  {{ $t("footerData.footerList3_5") }}
                </a>
              </li>
            </ul>
          </div>
				</el-col>
				<el-col :span="9" class="hidden-sm-and-down logo-area-dom">
          <div class="logo-area">
            <a href="https://www.jci-hitachi.com/" target="_blank">
              <img src="/imgStatic/logo-w.svg" alt="">
            </a>
          </div>
				</el-col>
			</el-row>
		<div class="bottom">
      <el-row>
        <el-col :xs="24" :sm="24" :md="24">
          <div class="footer-col-dom copyright">
            <!-- <div class="footer-col-dom-text zoom-box hidden-md-and-up">
              <div class="copyright-bold">© 2024 JOHNSON CONTROLS - HITACHI AIR CONDITIONING COMPANY</div>
            </div> -->
            <ul class="footer-col-dom-text flex-left">
              <li class="link-img-list">
                <a href="https://www.youtube.com/channel/UCzpGWQoRBHtqtelM-loWLVg" target="_blank" class="link-img-box">
                  <img src="/imgStatic/icon-youtube.svg" class="icon-normal" alt="">
                  <img src="/imgStatic/icon-youtube-hover.svg" class="icon-hover" alt="">
                </a>
                <a href="https://www.linkedin.com/company/hitachi-cooling-heating-global/" target="_blank" class="link-img-box">
                  <img src="/imgStatic/icon-linkedin.svg" class="icon-normal" alt="">
                  <img src="/imgStatic/icon-linkedin-hover.svg" class="icon-hover" alt="">
                </a>
                <a href="https://twitter.com/Hitachicool_GB" target="_blank" class="link-img-box">
                  <img src="/imgStatic/icon-twitter.svg" class="icon-normal" alt="">
                  <img src="/imgStatic/icon-twitter-hover.svg" class="icon-hover" alt="">
                </a>
                <a href="https://www.instagram.com/hitachicoolingheatingglobal/" target="_blank" class="link-img-box">
                  <img src="/imgStatic/icon-instagram.svg" class="icon-normal" alt="">
                  <img src="/imgStatic/icon-instagram-hover.svg" class="icon-hover" alt="">
                </a>
                <a href="https://www.facebook.com/HitachiCoolingHeatingGlobal/" target="_blank" class="link-img-box">
                  <img src="/imgStatic/icon-facebook.svg" class="icon-normal" alt="">
                  <img src="/imgStatic/icon-facebook-hover.svg" class="icon-hover" alt="">
                </a>
              </li>
            </ul>
					</div>
        </el-col>
      </el-row>
			<el-row>
				<el-col :xs="24" :sm="24" :md="11">
					<div class="footer-col-dom zoom-box">
            <ul>
              <li>
              <a href="https://www.johnsoncontrols.com/legal/terms" target="_blank">{{ $t("footerData.lawTitle") }}</a>
              </li>
              <li>
              <a href="https://www.johnsoncontrols.com/legal/privacy" target="_blank">{{ $t("footerData.privacyTitle") }}</a>
              </li>
              <li>
                <div class="trustarc-wrapper">
                  <div id="consent-banner"></div>
                  <div id="teconsent"></div>
                </div>
              </li>
            </ul>
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="13">
					<div class="footer-col-dom copyright">
            <div class="footer-col-dom-text zoom-box hidden-md-and-up">
              <div class="copyright-bold">{{ $t("footerData.copyright1") }}</div>
            </div>
            <ul class="footer-col-dom-text hidden-sm-and-down">
              <li>
                <font class="copyright-bold">{{ $t("footerData.copyright1") }}</font>
              </li>
            </ul>
					</div>
				</el-col>
			</el-row>
      <!-- <el-row>
        <div class="footer-col-dom record-dom">
          <ul>
						<li>
            <a href="http://beian.miit.gov.cn" target="_blank">{{ $t("footerData.recordTxt") }}</a>
						</li>
            <li>
            <a href="http://www.beian.gov.cn" target="_blank">{{ $t("footerData.policeRecordTxt") }}</a>
						</li>
					</ul>
        </div>
      </el-row> -->
		</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Footer',
	props: {
		msg: String,
	},
	data() {
		return {
			showLangMenu: false,
		};
	},
	created() {},
	mounted() {
		if (document.querySelector('#consent-box')) {
			document.querySelector('#consent-box').remove();
		}
		const script = document.createElement('script');
		script.id = 'consent-box';
		script.src = 'https://consent.trustarc.com/v2/notice/wqstc3';
		document.head.appendChild(script);
	},
	methods: {
		handleToPage(path) {
			if (this.$route.path === path) {
				return;
			}

			window.location.href = path;
		},
	},
	computed: {
		webMode() {
			return this.$store.state.webMode;
		},
	},
	watch: {},
};
</script>

<style scoped lang="scss">
.page-footer {
  width: 100%;
  padding: 100px 0 60px;
  background-color: #000000;
  overflow: hidden;
  .page-content-box {
    padding-left: 12px;
    padding-right: 12px;
    .logo-area-dom {
      display: flex;
      justify-content: flex-end;
    }
    .logo-area {
      display: flex;
      margin-bottom: 40px;
      img {
        margin-left: auto;
        width: 136px;
        cursor: pointer;
      }
    }
    .logo-area-mobile {
      img {
        margin-left: 0;
      }
    }
    .footer-col-dom-box {
      display: flex;
      .footer-col-dom {
        width: 50%;
      }
    }
    .footer-col-dom {
      // padding: 0 12px;
      h5 {
        font-size: 12px;
        font-weight: bold;
        color: #FFFEFE;
        line-height: 12px;
        letter-spacing: .05em;
        text-transform: uppercase;
        padding-bottom: 0.5em;
      }
      .medium-h5 {
        padding-bottom: 0;
      }
      ul {
        padding-left: 0;
        list-style: none;
        li {
          display: flex;
          align-items: center;
          // margin: 17px 0;
          text-decoration: none;
          color: #7f7f7f;
          font-weight: bold;
          font-size: 12px;
          line-height: 30px;
          // text-transform: uppercase;
          -webkit-transition: color .3s ease-in-out 0ms;
          transition: color .3s ease-in-out 0ms;
          a {
            text-decoration: none;
            color: #7f7f7f;
            font-weight: bold;
            font-size: 12px;
            line-height: 30px;
            text-transform: uppercase;
            -webkit-transition: color .3s ease-in-out 0ms;
            transition: color .3s ease-in-out 0ms;
          }
          a:hover {
            color: #fff;
          }
          /deep/#teconsent a {
            text-decoration: none;
            color: #7f7f7f;
            font-weight: bold;
            font-size: 12px;
            line-height: 30px;
            text-transform: uppercase;
            -webkit-transition: color .3s ease-in-out 0ms;
            transition: color .3s ease-in-out 0ms;
          }
          /deep/#teconsent a:hover {
            color: #fff;
          }
          .wx-share {
            margin-left: 17px;
            cursor: pointer;
          }
          .wx-share:hover {
            opacity: .6;
          }
        }
      }
      .footer-col-dom-text {
        text-decoration: none;
        color: #7f7f7f;
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
        -webkit-transition: color .3s ease-in-out 0ms;
        transition: color .3s ease-in-out 0ms;
        .copyright-bold {
          font-weight: bolder;
        }
      }

      .link-img-list {
        display: flex;
        align-items: center;

        .link-img-box {
          margin-left: 24px;
          .icon-hover {
            display: none;
          }
        }
        .link-img-box:hover {
          .icon-normal {
            display: none;
          }
          .icon-hover {
            width: 100%;
            display: inline;
          }
        }
        .link-img-box:first-child {
          margin-left: 0;
        }
      }
    }
    .bottom {
      ul {
        li {
          display: inline-block;
          margin-right: 60px;
        }
      }
      .copyright {
        padding-right: 0;
        ul {
          display: flex;
          justify-content: flex-end;
          li {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.app-pc {
  .page-footer {
    padding: 110px 0 70px !important;
    .footer-col-dom {
      h5 {
        // font-size: 16px !important;
        // line-height: 16px !important;
        // padding-bottom: 10px !important;
      }
      ul {
        li {
          // margin-top: 13px !important;
          // margin-bottom: 13px !important;
          font-size: 16px !important;
          a {
            // font-size: 16px !important;
          }
        }
      }
    }
    .footer-col-dom-text {
      font-size: 16px !important;
    }
    .bottom {
      margin-top: 100px;
      ul {
        margin: 0;
      }
      .copyright {
        ul {
          li {
            font-size: 12px !important;
          }
        }
      }
      .record-dom {
        text-align: right;
        li {
          margin-top: 0 !important;
          margin-right: 0 !important;
          margin-left: 30px;
          a {
            font-size: 14px !important;
          }
        }
      }
    }
  }
}
.app-mobile {
  .page-footer {
    padding: 40px 0;
    .menu-dom {
      margin-bottom: 50px;
    }
    .last-menu-dom {
      width: 100%;
    }

    .zoom-box {
      font-size: 10px !important;
      zoom: .8;
    }

    .flex-left {
      justify-content: flex-start !important;
    }

    .footer-col-dom {
      ul {
        li {
          a {
            font-size: 11px !important;
            // line-height: 15px !important;
          }
        }
      }
    }
    .footer-col-dom-text {
      margin-top: 10px;
      font-size: 10px !important;
    }
    .bottom {
      padding-bottom: 30px;
      ul {
        li {
          margin-right: 30px;
        }
      }
      .record-dom {
        li {
          margin-top: 0 !important;
        }
      }
    }
  }
}
</style>
