import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		loading: false,
		webMode: 1, // 1: pc; 2: H5
		lang: 'zh',
		scala: 1, // 页面与设计尺寸比例
		menuConfig: [], // 导航数据
		keyConfig: {
			zh: 'cn',
			en: 'en',
			ja: 'jp',
		},
	},
	mutations: {
		setLoading(state, data) {
			state.loading = data;
		},
		setWebMode(state, data) {
			state.webMode = data;
		},
		setLang(state, data) {
			state.lang = data;
		},
		setScala(state, data) {
			state.scala = data;
		},
		setMenuConfig(state, data) {
			state.menuConfig = data;
		},
	},
	actions: {
	},
	modules: {
	},
});
