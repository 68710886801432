import axios from 'axios';
import { Message } from 'element-ui';
import Store from '../store/index';
import i18n from '../lang';

const $store = Store;
const $toast = Message;

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.timeout = 10000;

// 请求数据拦截器
axios.interceptors.request.use(request => request, err => Promise.reject(err));

// 不走统一报错与数据处理的接口配置
const exceptionAPI = [];

// token失效错误码捕获
const tokenErrCode = [];
// 接收响应拦截器
axios.interceptors.response.use(
	// eslint-disable-next-line consistent-return
	(response) => {
		// loading close
		$store.commit('setLoading', false);
		if (tokenErrCode.includes(response.data.code) || tokenErrCode.includes(response.data.code)) {
			//
		} else if ([200, 201].includes(response?.status) || exceptionAPI.includes(response.config.url)) {
			return response;
		} else {
			$toast(response?.error || ''); // todo 接口报错信息
		}
	},
	(error) => {
		$store.commit('setLoading', false);
		if (error.response) {
			const { status } = error.response;
			switch (status) {
				case 404:
					$toast(i18n.t('axiosData.noApiErrorTips'));
					break;
				default:
					$toast(i18n.t('axiosData.serviceErrorTips'));
					break;
			}
		}
		return Promise.reject(error);
	},
);

const http = {
	get: (url, data) => {
		$store.commit('setLoading', true);
		return new Promise((resolve, reject) => {
			if (typeof data === 'object') {
				/* it goes in here when the user has added a data param when calling the get method */
				axios({
					method: 'GET',
					url,
					params: {
						...data,
					},
				}).then((res) => {
					const resultData = exceptionAPI.includes(url) ? res : (res.data);
					resolve(resultData);
				}).catch((err) => {
					reject(err);
				});
			} else {
				axios({
					method: 'GET',
					url,
				}).then((res) => {
					const resultData = exceptionAPI.includes(url) ? res : (res.data);
					resolve(resultData);
				}).catch((err) => {
					reject(err);
				});
			}
		});
	},
	post: (url, data) => {
		$store.commit('setLoading', true);
		return new Promise((resolve, reject) => {
			if (typeof data === 'object') {
				/* it goes in here when the user has added a data param when calling the get method */
				axios({
					method: 'POST',
					url,
					data,
				}).then((res) => {
					const resultData = exceptionAPI.includes(url) ? res : (res.data);
					resolve(resultData);
				}).catch((err) => {
					reject(err);
				});
			} else {
				axios({
					method: 'POST',
					url,
				}).then((res) => {
					const resultData = exceptionAPI.includes(url) ? res : (res.data);
					resolve(resultData);
				}).catch((err) => {
					reject(err);
				});
			}
		});
	},
	formPost: (url, data) => {
		$store.commit('setLoading', true);
		return new Promise((resolve, reject) => {
			if (typeof data === 'object') {
			/* it goes in here when the user has added a data param when calling the get method */
				const formData = new FormData();

				Object.keys(data).forEach(key => {
					formData.append(key, data[key]);
				});

				axios({
					method: 'POST',
					url,
					data: formData,
				}).then((res) => {
					const resultData = exceptionAPI.includes(url) ? res : res?.data;
					resolve(resultData);
				}).catch((err) => {
					reject(err);
				});
			} else {
				axios({
					method: 'POST',
					url,
				}).then((res) => {
					const resultData = exceptionAPI.includes(url) ? res : res?.data;
					resolve(resultData);
				}).catch((err) => {
					reject(err);
				});
			}
		});
	},
	upload: (url, data) => {
		$store.commit('setLoading', true);
		return new Promise((resolve, reject) => {
			const file = new FormData();
			file.append('file', data);
			axios.post(url, file, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}).then((res) => {
				resolve(res.data);
			}).catch((err) => {
				reject(err);
			});
		});
	},
	setHead: (data) => {
		console.log(data);
		// 此处可以动态设置统一header
	},
};

export default http;
